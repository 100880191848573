import { type LicensedEstablishment } from 'src/types/api'
import { create } from 'zustand'

interface SetLe {
  passedLe: LicensedEstablishment | undefined
  setPassedLe: (passedLeId: LicensedEstablishment) => void
}

export const useSetLe = create<SetLe>((set) => ({
  passedLe: undefined,
  setPassedLe: (passedLe: any) => {
    set({ passedLe })
  },
}))
