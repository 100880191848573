import { Tab, Tabs } from '@mui/material'
import { useGetMe } from 'hooks/api/useGetMe'
import { Link, useNavigate } from 'react-router-dom'
import { SelectLicensedEstablishment } from './SelectLicensedEstablishment'
import { type LicensedEstablishment } from 'src/types/api'
import { useSetLe } from 'stores/useSetLe'
import { useEffect } from 'react'

export const ReportsTabs = ({
  currentTab,
  locationId,
}: {
  currentTab: string
  locationId: number
}) => {
  const navigate = useNavigate()
  const meQuery = useGetMe()
  const financialPermission = meQuery.data?.user?.reportsFinancialPermission
  const nonFinancialPermission =
    meQuery.data?.user?.reportsNonFinancialPermission

  const setPassedLe = useSetLe((state) => state.setPassedLe)
  const { passedLe } = useSetLe()

  useEffect(() => {
    if (passedLe?.id === undefined) {
      navigate('/Reports')
    }
  }, [passedLe])

  const nonFinancialTabs = [
    {
      label: 'Patron Activity',
      value: `1`,
      to: `/Reports/PatronActivity`,
    },
  ]

  const financialTabs = [
    {
      label: 'Credits Wagered by Hour',
      value: `2`,
      to: `/Reports/CreditsWageredByHour`,
    },
    {
      label: 'Credits Wagered by Machine',
      value: `3`,
      to: `/Reports/CreditsWageredByMachine`,
    },
    {
      label: 'Credits Wagered: Hourly Trends',
      value: `4`,
      to: `/Reports/CreditsWageredHourlyTrends`,
    },
    {
      label: 'NTI Trends',
      value: `5`,
      to: `/Reports/TrailingNtiTrends`,
    },
    {
      label: 'Patron Check-ins',
      value: `6`,
      to: `/Reports/PatronCheckins`,
    },
    {
      label: 'Patron List',
      value: `7`,
      to: `/Reports/PatronList`,
    },
    {
      label: 'Patron Reward Redemptions',
      value: `8`,
      to: `/Reports/PatronRewardRedemptions`,
    },
    {
      label: 'VGT Snapshot',
      value: `9`,
      to: `/Reports/VgtSnapshot`,
    },
    {
      label: 'VGT Trends',
      value: `10`,
      to: `/Reports/VgtTrends`,
    },
    {
      label: 'Weekly Snapshot',
      value: '',
      to: `/Reports/WeeklySnapshotLocationSelect`,
    },
  ]

  return (
    <div>
      <SelectLicensedEstablishment
        onSelectLicensedEstablishment={(selectedLe: LicensedEstablishment) => {
          // Update the passed LE
          setPassedLe(selectedLe)
        }}
        locationId={passedLe?.id}
      />
      <Tabs
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab
          label="Overview"
          value="/Reports/ReportsOverview"
          to="/Reports"
          component={Link}
        />
        {nonFinancialPermission &&
          nonFinancialTabs.map((tab) => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={tab.value}
              to={tab.to}
              component={Link}
              disabled={isNaN(locationId) && passedLe === undefined}
            />
          ))}
        {financialPermission &&
          financialTabs.map((tab) => (
            <Tab
              key={tab.label}
              label={tab.label}
              value={tab.value}
              to={tab.to}
              component={Link}
              disabled={isNaN(locationId) && passedLe === undefined}
            />
          ))}
      </Tabs>
    </div>
  )
}
