import { type GridRowSelectionModel, type GridColDef } from '@mui/x-data-grid'
import {
  chain,
  concat,
  difference,
  flatten,
  get,
  isEmpty,
  isNil,
  compact,
  uniqBy,
} from 'lodash'
import { useMemo, useState } from 'react'
import { useLayout } from 'hooks/useLayout'
import { Page } from 'components/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { useNavigate, useParams } from 'react-router-dom'
import noLocationFound from 'assets/JJlocationNotFound.png'
import { Box, Button, Divider, Typography } from '@mui/material'
import { useGetSweepstakeById } from 'hooks/api/useGetSweepstakeById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import {
  dataTableAddressFormatter,
  dataTableLicenseNumberFormatter,
  pluralize,
} from 'utils/util'
import { CorporateAccountsTreeDataGrid } from 'components/TreeDataGrid/CorporateAccountsTreeDataGrid'
import { useGetLesWithCaAndOrgAssociatedToUsersCa } from 'hooks/api/useGetLesWithCaAndOrgAssociatedToUsersCa'
import { useSweepstakeParticipatingLocationsAndEnrollmentAgreement } from 'stores/useSweepstakeParticipatingLocationsAndEnrollmentAgreement'
import { HandleOnChangeSelection } from './HandleOnChangeSelection'
import {
  type Sweepstake,
  type CorporateAccount,
  type LicensedEstablishment,
  type Organization,
} from 'src/types/api'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'

export const defaultColumnsForEnrollment = (
  isMobile: boolean
): GridColDef[] => {
  return [
    {
      field: 'name',
      headerName: 'Account Name',
      flex: 1,
      renderCell: (params) => {
        const isLicensedEstablishment =
          get(params.row, 'corporateAccount') !== undefined &&
          get(params.row, 'organization') !== undefined

        const displayName = isLicensedEstablishment
          ? get(params.row, 'standardName', '')
          : get(params.row, 'name')

        return <p>{displayName}</p>
      },
    },
    {
      field: 'licenseNumber',
      headerName: 'License Number',
      flex: 0.5,
      valueFormatter: dataTableLicenseNumberFormatter(isMobile),
      renderCell: (params) => {
        const isALicensedEstablishment =
          get(params.row, 'corporateAccount') !== undefined &&
          get(params.row, 'organization') !== undefined

        return (
          <p>
            {isEmpty(params.formattedValue) && !isALicensedEstablishment
              ? '-'
              : `#${String(params.formattedValue)}`}
          </p>
        )
      },
    },
    {
      field: 'addresses',
      headerName: 'Address',
      flex: 1,
      valueFormatter: dataTableAddressFormatter(true),
      renderCell: (params) => {
        const isALicensedEstablishment =
          get(params.row, 'corporateAccount') !== undefined &&
          get(params.row, 'organization') !== undefined

        return (
          <p className="overflow-hidden whitespace-nowrap text-ellipsis	">
            {params.formattedValue === 'Unspecified Address' &&
            !isALicensedEstablishment
              ? '-'
              : params.formattedValue}
          </p>
        )
      },
    },
  ]
}

const Content = ({
  sweepstake,
  licensedEstablishments,
  organizations,
  corporateAccounts,
}: {
  sweepstake: Sweepstake
  licensedEstablishments: LicensedEstablishment[]
  organizations: Organization[]
  corporateAccounts: CorporateAccount[]
}) => {
  const { isMobile } = useLayout()
  const navigate = useNavigate()
  const { currentCorporateAccountName } = useCurrentCorporateAccountStore()
  const availableLicensedEstablishments = licensedEstablishments.filter(
    (le) => !le.excludedFromSweepstakes
  )

  const { setParticipatingLocations, participatingLocations } =
    useSweepstakeParticipatingLocationsAndEnrollmentAgreement()

  const selectedLocationKeys = useMemo(() => {
    if (isNil(participatingLocations)) {
      return []
    }

    return participatingLocations.map((leId) => `licensedEstablishment-${leId}`)
  }, [participatingLocations])

  const enrolledLocationKeys = useMemo(() => {
    if (isNil(sweepstake.licensedEstablishments)) {
      throw Error('No sweepstake data')
    }

    return sweepstake.licensedEstablishments.map(
      (le) => `licensedEstablishment-${le?.id}`
    )
  }, [sweepstake])

  const selectedOrEnrolledLocationKeys = useMemo(
    () => concat(enrolledLocationKeys, selectedLocationKeys),
    [enrolledLocationKeys, selectedLocationKeys]
  )

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>(
    new HandleOnChangeSelection({
      previousRows: [],
      rows: selectedOrEnrolledLocationKeys,
      licensedEstablishments: availableLicensedEstablishments,
      enrolledLicensedEstablishmentIds:
        sweepstake.licensedEstablishments?.map((x) => x.id) ?? [],
      organizations,
      corporateAccounts,
      selectedRows: selectedOrEnrolledLocationKeys,
    }).getInitialSelection()
  )

  const selectedLeCount = useMemo(() => {
    return difference(selectedRowKeys, enrolledLocationKeys).filter(
      (x) => x.split('-')[0] === 'licensedEstablishment'
    ).length
  }, [selectedRowKeys, enrolledLocationKeys])

  const handleOnChange = (rowSelectionModel: GridRowSelectionModel) => {
    setSelectedRowKeys((prev) =>
      new HandleOnChangeSelection({
        previousRows: prev,
        rows: concat(
          flatten(rowSelectionModel.map((x) => String(x))),
          enrolledLocationKeys
        ),
        licensedEstablishments: availableLicensedEstablishments,
        enrolledLicensedEstablishmentIds:
          sweepstake.licensedEstablishments?.map((x) => x.id) ?? [],
        organizations,
        corporateAccounts,
        selectedRows: concat(selectedRowKeys, enrolledLocationKeys),
      }).handleCorporateAccountAndOrgSelection()
    )
  }

  const handleOnSave = () => {
    const justLeLocations = chain(selectedRowKeys)
      .map((x) => {
        const base = x.split('-')
        const type = base[0]
        const id = Number(base[1])
        if (type === 'licensedEstablishment') {
          return id
        }
        return null
      })
      .compact()
      .value()

    setParticipatingLocations(justLeLocations)
    navigate(`/Sweepstakes/${sweepstake?.id}/AdditionalEnrollmentDetails`)
  }

  return (
    <Page
      header={
        <>
          <PageHeader
            title="Enroll Locations"
            isSecondary={true}
            backText={`${sweepstake.name}`}
            backPath={`/Sweepstakes/${sweepstake.id}/ParticipatingLocations`}
          >
            <Typography
              variant="body-1"
              className="text-secondary"
              paddingTop="12px"
              paddingBottom="24px"
            >
              Select which of your locations you want to enroll in{' '}
              {sweepstake.name} for {currentCorporateAccountName}. Please note
              that you cannot unenroll locations from this table.
            </Typography>
          </PageHeader>
          <Divider />
        </>
      }
      footer={
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            columnGap={2}
          >
            <Box width={'100%'} alignContent={'center'}>
              <Typography variant="body-1" color={'text.secondary'}>
                {pluralize(selectedLeCount, 'Licensed Establishment')} Selected
                to Enroll in Sweepstakes
              </Typography>
            </Box>
            <Button
              variant="text"
              onClick={() => {
                setParticipatingLocations(undefined)
                if (isNil(sweepstake)) {
                  throw Error("Sweepstake data doesn't exist")
                }
                navigate(`/Sweepstakes/${sweepstake.id}/ParticipatingLocations`)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={selectedLeCount === 0}
              onClick={() => handleOnSave()}
            >
              Next
            </Button>
          </Box>
        </Box>
      }
    >
      {availableLicensedEstablishments.length === 0 ? (
        <img src={noLocationFound} alt="No location found" />
      ) : (
        <>
          <Typography variant="h3" pb={'18px'}>
            My Enrollable {currentCorporateAccountName} Locations
          </Typography>
          <CorporateAccountsTreeDataGrid
            columns={defaultColumnsForEnrollment(isMobile)}
            corporateAccounts={corporateAccounts}
            organizations={organizations}
            licensedEstablishments={availableLicensedEstablishments}
            checkboxSelection
            rowSelectionModel={selectedRowKeys}
            onRowSelectionModelChange={handleOnChange}
            hideFooter={availableLicensedEstablishments.length <= 20}
            initialState={{
              pagination: { paginationModel: { pageSize: 20 } },
            }}
            hideFooterSelectedRowCount
            pageSizeOptions={[20, 75, 100]}
            getRowClassName={(params) => {
              const id = Number(params.row.id)
              const isLicensedEstablishment =
                get(params.row, 'corporateAccount') !== undefined &&
                get(params.row, 'organization') !== undefined
              const isCorporateAccount =
                get(params.row, 'smartID') !== undefined
              const isOrganization =
                get(params.row, 'parentOrganization') !== undefined

              if (isLicensedEstablishment) {
                const isSelected =
                  selectedRowKeys.find(
                    (x) => x === `licensedEstablishment-${id}`
                  ) !== undefined

                if (isSelected) {
                  return 'selected'
                }
              } else if (isOrganization) {
                const isSelected =
                  selectedRowKeys.find((x) => x === `organization-${id}`) !==
                  undefined

                if (isSelected) {
                  return 'selected'
                }
              } else if (isCorporateAccount) {
                const isSelected =
                  selectedRowKeys.find(
                    (x) => x === `corporateAccount-${id}`
                  ) !== undefined

                if (isSelected) {
                  return 'selected'
                }
              }

              return ''
            }}
            isRowSelectable={(params) => {
              const id = Number(params.row.id)
              const isLicensedEstablishment =
                get(params.row, 'corporateAccount') !== undefined &&
                get(params.row, 'organization') !== undefined
              const isCorporateAccount =
                get(params.row, 'smartID') !== undefined
              const isOrganization =
                get(params.row, 'parentOrganization') !== undefined

              if (isLicensedEstablishment) {
                return (
                  enrolledLocationKeys.find(
                    (x) => Number(x.split('-')[1]) === id
                  ) === undefined
                )
              }

              if (isCorporateAccount) {
                const lesWithCa = availableLicensedEstablishments
                  .filter((x) => x.corporateAccountId === id)
                  .map((x) => `licensedEstablishment-${x.id}`)

                if (difference(lesWithCa, enrolledLocationKeys).length === 0) {
                  return false
                }
              }

              if (isOrganization) {
                const lesWithOrg = availableLicensedEstablishments
                  .filter((x) => x.organizationId === id)
                  .map((x) => `licensedEstablishment-${x.id}`)

                if (difference(lesWithOrg, enrolledLocationKeys).length === 0) {
                  return false
                }
              }
              return true
            }}
          />
        </>
      )}
    </Page>
  )
}

export const ManageEnrollLocationsPage = () => {
  const { id: idParam } = useParams()
  const sweepstakeId = Number(idParam)
  const sweepstakeQuery = useGetSweepstakeById({ sweepstakeId })

  const {
    isPending: getAllPending,
    isError: getAllError,
    licensedEstablishments,
    organizations,
    corporateAccounts,
  } = useGetLesWithCaAndOrgAssociatedToUsersCa()

  const availableLicensedEstablishments = useMemo(() => {
    return uniqBy(
      compact(
        concat(
          sweepstakeQuery.data?.licensedEstablishments?.filter(
            (le) => !le.excludedFromSweepstakes
          ),
          licensedEstablishments.filter((le) => !le.excludedFromSweepstakes)
        )
      ),
      'id'
    )
  }, [licensedEstablishments, sweepstakeQuery])

  if (
    sweepstakeQuery.isPending ||
    getAllPending ||
    isNil(sweepstakeQuery.data)
  ) {
    return <ActivityIndicator />
  }

  if (sweepstakeQuery.isError) {
    return <Box>An error occurred fetching the sweepstake.</Box>
  }

  if (getAllError) {
    return (
      <Box>
        An error occurred fetching the licensed Establishments/corporate
        accounts/organizations.
      </Box>
    )
  }

  return (
    <Content
      sweepstake={sweepstakeQuery.data}
      licensedEstablishments={availableLicensedEstablishments}
      organizations={organizations}
      corporateAccounts={corporateAccounts}
    />
  )
}
