import { LicensesContact } from './LicensesContact'
import { Box, useTheme } from '@mui/material'
import { LicensesListLicensedEstablishmentPage } from './LicensesListLicensedEstablishmentPage'
import { Page } from 'components/Page'
import { PageHeader } from 'components/Shared/PageHeader'

export const LicensesLicensedEstablishmentPage = () => {
  const theme = useTheme()

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Licenses"
            isSecondary={true}
            headerBgColor="white"
          />
        </Box>
      }
    >
      <Box sx={{ backgroundColor: theme.palette.secondary.light }}>
        <Box
          px={{ xs: '16px', sm: '41px' }}
          pt={{ xs: '21px', sm: '32px' }}
          pb={'1%'}
        >
          <LicensesListLicensedEstablishmentPage />
          <LicensesContact />
        </Box>
      </Box>
    </Page>
  )
}
