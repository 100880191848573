import { Stack, Typography } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { format } from 'date-fns'
import { useGetVgtTrends } from 'hooks/api/Reports/useGetVgtTrends'
import { VgtTrendsChart } from './VgtTrendsChart'
import { getReportStartAndEndDates } from 'utils/util'
import { ReportsHeader } from '../ReportsHeader'
import { useSetLe } from 'stores/useSetLe'

export const VgtTrendsPage = () => {
  const { passedLe } = useSetLe()
  const { startDate, endDate } = getReportStartAndEndDates(13, 1)

  const reportQuery = useGetVgtTrends({
    licensedEstablishmentId: Number(passedLe?.id),
    startDate,
    endDate,
  })

  const formattedStartDate = format(startDate, 'MM/dd/yyyy')
  const formattedEndDate = format(endDate, 'MM/dd/yyyy')

  interface RawDataItem {
    amount: number
    vgtNumber: string
    date: string
    name: string
    tagNumber: number
  }

  interface TransformedDataItem {
    date: string
    [key: string]: { amount: number; name: string; tagNumber: number } | string
  }

  const transformData = (rawData: RawDataItem[]): TransformedDataItem[] => {
    const groupedByDate: Record<
      string,
      Record<string, { amount: number; name: string; tagNumber: number }>
    > = {}

    rawData.forEach((item) => {
      const formattedDate = format(new Date(item.date), 'yyyy-MM-dd')
      if (!groupedByDate[formattedDate]) {
        groupedByDate[formattedDate] = {}
      }
      groupedByDate[formattedDate][item.vgtNumber] = {
        amount: item.amount,
        name: item.name,
        tagNumber: item.tagNumber,
      }
    })

    return Object.keys(groupedByDate).map((date) => ({
      date,
      ...groupedByDate[date],
    }))
  }

  return (
    <ReportsHeader currentTab={'10'}>
      <Typography variant="h4">
        {`Last 13 Weeks: ${formattedStartDate} - ${formattedEndDate}`}
      </Typography>
      <Stack spacing={4}>
        {reportQuery.isPending && reportQuery.isFetching && (
          <ActivityIndicator />
        )}
        {reportQuery.isError && <ErrorIndicator />}
        {reportQuery.data &&
          (reportQuery.data.metrics.length === 0 ? (
            <Typography variant="h4"></Typography>
          ) : (
            <VgtTrendsChart
              data={transformData(reportQuery.data.metrics)}
              endDate={endDate}
            />
          ))}
      </Stack>
    </ReportsHeader>
  )
}
