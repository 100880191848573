import { Button, Grid, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { format } from 'date-fns'
import { useGetCreditsWageredHourlyTrends } from 'hooks/api/Reports/useGetCreditsWageredHourlyTrends'
import { CreditsWageredHourlyTrendsChart } from './CreditsWageredHourlyTrendsChart'
import { getReportStartAndEndDates } from 'utils/util'
import { ReportsHeader } from '../ReportsHeader'
import { useSetLe } from 'stores/useSetLe'

export const CreditsWageredHourlyTrendsPage = () => {
  const { passedLe } = useSetLe()
  const { startDate, endDate } = getReportStartAndEndDates(4, 1)

  const reportQuery = useGetCreditsWageredHourlyTrends({
    licensedEstablishmentId: Number(passedLe?.id),
    startDate,
    endDate,
  })

  const formattedStartDate = format(startDate, 'MM/dd/yyyy')
  const formattedEndDate = format(endDate, 'MM/dd/yyyy')

  const [displayType, setDisplayType] = useState<'$' | '%'>('$')

  const buttonStyle = (type: '$' | '%') => ({
    maxWidth: '40px',
    maxHeight: '40px',
    minWidth: '40px',
    minHeight: '40px',
    fontSize: '1.8rem',
    backgroundColor: displayType === type ? '#CC2027' : '#e0e0e0',
    color: displayType === type ? 'white' : 'black',
  })

  return (
    <ReportsHeader currentTab={'4'}>
      <Typography variant="h4">
        {`Last 4 Weeks: ${formattedStartDate} - ${formattedEndDate}`}
      </Typography>
      <Stack spacing={4}>
        <Grid container spacing={1} alignItems="center">
          <Grid item sx={{ mt: 5 }}>
            <Button
              variant="contained"
              onClick={() => setDisplayType('$')}
              style={buttonStyle('$')}
              title="Total Credits Wagered"
            >
              $
            </Button>
          </Grid>
          <Grid item sx={{ mt: 5 }}>
            <Button
              variant="contained"
              onClick={() => setDisplayType('%')}
              style={buttonStyle('%')}
              title="Percentage of Total Credits Wagered"
            >
              %
            </Button>
          </Grid>
        </Grid>

        {reportQuery.isPending && reportQuery.isFetching && (
          <ActivityIndicator />
        )}
        {reportQuery.isError && <ErrorIndicator />}
        {reportQuery.data &&
          (reportQuery.data.metrics.length === 0 ? (
            <Typography variant="h4"></Typography>
          ) : (
            <CreditsWageredHourlyTrendsChart
              data={reportQuery.data.metrics}
              displayType={displayType}
              endDate={endDate}
            />
          ))}
      </Stack>
    </ReportsHeader>
  )
}
