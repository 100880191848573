import { isPresent } from '@jjvgaming/player-payback-library'
import _ from 'lodash'

export const allPaths = [
  '/',
  '/CreateAccount',
  '/Home',
  '/Development/Home',
  '/Compliance',
  '/PatronAccounts',
  '/Locations',
  '/Reports',
  '/FlexPay',
  '/Reports/ReportsOverview',
  '/Reports/PatronActivity',
  '/Reports/WeeklySnapshot',
  '/Reports/WeeklySnapshotLocationSelect',
  '/Reports/PatronCheckins',
  '/Reports/PatronList',
  '/Reports/PatronRewardRedemptions',
  '/Reports/CreditsWageredByMachine',
  '/Reports/CreditsWageredByHour',
  '/Reports/VgtSnapshot',
  '/Reports/VgtTrends',
  '/Reports/CreditsWageredHourlyTrends',
  '/Reports/TrailingNtiTrends',
  '/Rewards',
  '/Promotions',
  '/Promotions/:id',
  '/Sweepstakes',
  '/Sweepstakes/:id/Overview',
  '/Sweepstakes/:id/Winners',
  '/Sweepstakes/:id/ParticipatingLocations',
  '/Sweepstakes/:id/EnrollLocations',
  '/Sweepstakes/:id/AdditionalEnrollmentDetails',
  '/Sweepstakes/:id/ReviewLocationsToEnroll',
  '/LicensedEstablishments/:id',
  '/LicensedEstablishmentUsers',
  '/LicensedEstablishmentUsers/NewLEConnectUserPage',
  // '/LiveAtLocation',
  // '/LiveAtLocation/PatronDetails/:id/LicensedEstablishment/:licensedEstablishmentId',
  // '/LiveAtLocation/PatronDetails/:id',
  '/RewardsProgram/:id',
  // '/RewardsProgram/:id/Settings',
  '/RewardsProgram/:id/Redemption',
  '/RewardsProgram/:id/ActivityLog',
  '/RewardsProgram/:id/RewardsCatalog/:rewardCatalogId',
] as const

const allPathsAsRegExps = allPaths.map(
  (path) => new RegExp('^' + path.replace(/:\w*id/gi, '\\d+') + '$')
)

export const findPath = (path: string) => {
  const foundPath = _.find(allPathsAsRegExps, (x) => x.test(path))
  if (!foundPath) {
    return null
  }
  return foundPath
}

export type Path = (typeof allPaths)[number]
export const isPath = (maybePath: string): maybePath is Path =>
  isPresent(findPath(maybePath))
