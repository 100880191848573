import { type GridColDef } from '@mui/x-data-grid'
import { DataTable } from 'components/Shared/DataTable'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'
import { colorPrimitives } from 'components/Theme'
import type { FullPatronCheckInDTO } from 'src/types/api'
import { pathPermissions } from 'src/navigation/pathPermissions'
import { formatPhoneNumber } from '@jjvgaming/player-payback-library'
import { useSnackbar } from 'stores/useSnackbar'
import { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { ReportExportModal } from '../ReportExportModal'
import ExportIcon from 'assets/export.svg'

interface PatronListTableProps {
  data: FullPatronCheckInDTO[]
  downloadPIIPermission: boolean
}

// PII permission columns
const columns: GridColDef[] = pathPermissions['/Reports/PatronList'] && [
  {
    field: 'patronId',
    headerName: 'Patron ID',
    minWidth: 100,
    flex: 1,
    valueGetter: (params) => params.row.patronId,
  },
  {
    field: 'firstName',
    headerName: 'First Name',
    minWidth: 120,
    flex: 1,
    valueGetter: (params) => params.row.patron.firstName,
  },
  {
    field: 'lastName',
    headerName: 'Last Name',
    minWidth: 120,
    flex: 1,
    valueGetter: (params) => params.row.patron.lastName,
  },
  {
    field: 'phoneNumber',
    headerName: 'Phone',
    minWidth: 140,
    flex: 1,
    valueGetter: (params) => params.row.patron.phoneNumber ?? '',
    valueFormatter: ({ value }) => formatPhoneNumber(value),
  },
  {
    field: 'email',
    headerName: 'Email',
    minWidth: 300,
    flex: 1,
    valueGetter: (params) => params.row.patron.email ?? '',
  },
  {
    field: 'addresses',
    minWidth: 400,
    headerName: 'Address',
    flex: 2,
    renderCell: (params) => {
      return (
        <p className="overflow-hidden whitespace-nowrap text-ellipsis">
          {(params.row.patron.addresses[0] &&
            `${params.row.patron.addresses[0].address1}` +
              (params.row.patron.addresses[0].address2
                ? `, ${params.row.patron.addresses[0].address2}`
                : '')) ??
            null}
        </p>
      )
    },
    valueGetter: (params) => {
      return (
        (params.row.patron.addresses[0] &&
          `${params.row.patron.addresses[0].address1}` +
            (params.row.patron.addresses[0].address2
              ? `, ${params.row.patron.addresses[0].address2}`
              : '')) ??
        null
      )
    },
  },
  {
    field: 'city',
    headerName: 'City',
    minWidth: 120,
    flex: 1,
    valueGetter: (params) =>
      params.row.patron.addresses.length
        ? params.row.patron.addresses[0].city
        : '',
  },
  {
    field: 'state',
    headerName: 'State',
    minWidth: 120,
    flex: 1,
    valueGetter: (params) =>
      params.row.patron.addresses.length
        ? params.row.patron.addresses[0].state
        : '',
  },
  {
    field: 'postalCode',
    headerName: 'Zip',
    minWidth: 120,
    flex: 1,
    valueGetter: (params) =>
      params.row.patron.addresses.length
        ? params.row.patron.addresses[0].postalCode
        : '',
  },
  {
    field: 'checkinTotal',
    headerName: 'Check-in Total',
    minWidth: 140,
    flex: 1,
    valueGetter: (params) => params.row.checkinTotal ?? '',
  },
  {
    field: 'pointsTotal',
    headerName: 'Points Total',
    minWidth: 140,
    flex: 1,
    valueGetter: (params) => params.row.pointsTotal ?? '',
  },
]

export const PatronListTable = ({
  data,
  downloadPIIPermission,
}: PatronListTableProps) => {
  const setMessage = useSnackbar((state) => state.setMessage)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModal = () => setIsModalOpen(!isModalOpen)

  return (
    <>
      <Box className="flex lg:justify-end">
        <div hidden={!downloadPIIPermission}>
          <Button variant="contained" onClick={toggleModal}>
            <img src={ExportIcon} width={15} height={15} alt="Export icon" />
            <Typography variant="label-CTA">Export</Typography>
          </Button>
        </div>
      </Box>
      <ReportExportModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        records={data}
        reportName={'Patron List'}
        exportColumns={columns}
        setMessage={setMessage}
      />
      <DataTable
        getRowId={(row) => row.patronId}
        slotProps={{
          row: {
            style: { cursor: 'default' },
          },
        }}
        imageSource={NoPatronsCheckedInIcon}
        noDataMessage="There are no patron accounts available"
        columns={columns}
        rows={data}
        sortFieldName="firstName"
        sx={{
          color: colorPrimitives.black,
          '& .MuiDataGrid-row:hover': {
            cursor: 'auto',
          },
        }}
      />
    </>
  )
}
