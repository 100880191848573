import { useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { Page } from 'components/Page'
import { type GridColDef, type GridEventListener } from '@mui/x-data-grid'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { PageHeader } from 'components/Shared/PageHeader'
import { Colors, colorPrimitives } from 'components/Theme'
import { useNavigate } from 'react-router-dom'
import { GridSearchField } from 'components/GridSearchField/GridSearchField'
import {
  type PromotionStatus,
  calculatePromotionStatus,
  dataTablePromotionDateFormatter,
} from 'utils/util'
import { useLayout } from 'hooks/useLayout'
import { DataTable } from 'components/Shared/DataTable'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { useGetMe } from 'hooks/api/useGetMe'
import { useGetPromotionsByCorporateAccountId } from 'hooks/api/useGetPromotionsByCorporateAccountId'
import { type Promotion } from 'src/types/api.ts'

const getColumns = (isMobile: boolean): GridColDef[] => [
  {
    field: 'title',
    headerName: 'Special & Event Name',
    minWidth: 380,
    flex: 1,
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    minWidth: 100,
    flex: 1,
    valueFormatter: dataTablePromotionDateFormatter(isMobile, 'Start'),
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    minWidth: 100,
    flex: 1,
    valueFormatter: dataTablePromotionDateFormatter(isMobile, 'End'),
  },
  {
    field: 'status',
    headerName: 'Status',
    minWidth: 100,
    flex: 1,
    valueGetter: (params) => {
      const startDate = params.row.startDate
        ? new Date(params.row.startDate)
        : null
      const endDate = params.row.endDate ? new Date(params.row.endDate) : null

      const promoStatusLabel = (status: PromotionStatus, isMobile: boolean) => {
        switch (status) {
          case 'scheduled':
            return isMobile ? 'Status: SCHEDULED' : 'SCHEDULED'
          case 'active':
            return isMobile ? 'Status: ACTIVE' : 'ACTIVE'
          case 'expired':
            return isMobile ? 'Status: EXPIRED' : 'EXPIRED'
          default:
            return ''
        }
      }

      const status = calculatePromotionStatus(startDate, endDate)
      return promoStatusLabel(status, isMobile)
    },
    renderCell: (params) => {
      const status = params.value
      const promoBackgroundColor: Record<string, string> = {
        ACTIVE: Colors.promotionsStatus.background.Active,
        EXPIRED: Colors.promotionsStatus.background.Expired,
        SCHEDULED: Colors.promotionsStatus.background.Scheduled,
      }

      return (
        <Box
          style={{
            backgroundColor: promoBackgroundColor[status],
            color: colorPrimitives.black,
            fontWeight: 'bold',
            borderRadius: '4px',
            padding: '6px',
            display: 'inline-block',
          }}
        >
          {status}
        </Box>
      )
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    minWidth: 215,
    flex: 1,
    valueGetter: (params) => {
      if (params.row.type === 'Global') {
        return 'J&J Promo'
      } else if (params.row.type === 'Location') {
        if (
          params.row.licensedEstablishments &&
          params.row.licensedEstablishments.length > 0
        ) {
          return `${params.row.licensedEstablishments?.length} Location(s)`
        }
      } else if (params.row.type === 'CorporateAccount') {
        const corporateAccount = params.row.corporateAccount
          ? params.row.corporateAccount
          : null
        if (corporateAccount) {
          return corporateAccount.name
        }
      } else if (params.row.type === 'Organization') {
        const organization = params.row.organization
          ? params.row.organization
          : null
        if (organization) {
          return organization.name
        }
      }
    },
  },
]

export const PromotionsPage = () => {
  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const navigate = useNavigate()
  const meQuery = useGetMe()
  const promotionsQuery = useGetPromotionsByCorporateAccountId({
    corporateAccountId: Number(selectedCorporateAccountId),
  })
  const { isMobile } = useLayout()
  const [filteredData, setFilteredData] = useState<Promotion[]>()

  const handleFilteredData = (filteredData: Promotion[]) => {
    setFilteredData(filteredData)
  }

  const selectCell: GridEventListener<'cellClick'> = (params) => {
    if (params.field !== '__menu__' && params.id !== null) {
      navigate(`/Promotions/${params.id}`)
    }
  }

  const sortedPromotions = useMemo(() => {
    if (!promotionsQuery.data) return []

    // Create a shallow copy of the promotions array
    const promotionsCopy = [...promotionsQuery.data]

    // Sort the copied array
    return promotionsCopy.sort((a, b) => {
      if (!a.startDate && !b.startDate) return 0
      if (!a.startDate) return -1
      if (!b.startDate) return 1

      return new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
    })
  }, [promotionsQuery.data])

  return (
    <Page
      header={
        <Box>
          <PageHeader
            title="Specials & Events"
            isSecondary={true}
            headerBgColor="white"
          />
        </Box>
      }
    >
      {meQuery.isPending && <ActivityIndicator />}
      {meQuery.isError && <ErrorIndicator />}
      {!meQuery.data?.user?.promotionsPermission ? (
        <NoDataIndicator
          noImage
          noDataMessage="Your current permissions do not allow access to view specials & events. To obtain access, please contact your Account Manager for assistance."
        />
      ) : (
        <>
          <Box className="pb-8">
            <GridSearchField<'FullPromotionDTO'>
              placeholder="Search Specials & Events"
              data={sortedPromotions}
              handleFilteredData={handleFilteredData}
              fullWidth
            />
          </Box>

          {promotionsQuery.isPending && <ActivityIndicator />}
          {promotionsQuery.isError && <p>An error occured.</p>}
          {!promotionsQuery.isError && promotionsQuery.data && (
            <DataTable
              columns={getColumns(isMobile)}
              rows={filteredData ?? sortedPromotions}
              onCellClick={selectCell}
            />
          )}
        </>
      )}
    </Page>
  )
}
