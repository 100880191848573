import { Box, Button, Link, Typography } from '@mui/material'
import JJConnectLogo from 'assets/jjConnectLogo.svg'
import { CheckboxInput } from 'components/CheckBox/CheckBox'
import { colorPrimitives } from 'components/Theme'
import { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import ErrorIcon from 'assets/errorIcon.svg'
import {
  MessageBanner,
  MessageBannerVariant,
} from 'components/MessageBanner/MessageBanner'
import { useEULAAcceptedDate } from 'stores/useEULAAcceptedDate'
import { EULADialog } from './EULADialog'

export const LegalDisclaimerSection = ({
  changeSection,
}: {
  changeSection: (section: string) => void
}) => {
  const [legalDisclaimers, setLegalDisclaimers] = useState({
    eula: false,
    error: '',
  })

  const { acceptedDate, setAcceptedDate } = useEULAAcceptedDate()

  const buildErrorMessage = () => {
    const errorMessage = (missingCheck: string) =>
      `You must read and accept ${missingCheck}`
    if (!legalDisclaimers.eula) {
      return errorMessage('the EULA to continue.')
    }
    return ''
  }

  const validateAndNavigateToNextSection = async () => {
    const validationPassed = acceptedDate !== ''
    if (validationPassed) {
      changeSection('emailSection')
    } else {
      setLegalDisclaimers((previous) => {
        return { ...previous, error: buildErrorMessage() }
      })
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModalIsOpen = () => {
    setIsModalOpen(!isModalOpen)
    setLegalDisclaimers((previous) => {
      return { ...previous, error: '' }
    })
  }

  return (
    <>
      <Box className="flex justify-center flex-col w-full">
        <img src={JJConnectLogo} alt="JJ Connect logo" />
        <Box className="pt-8">
          <Typography variant="h1" textAlign="center">
            Create Account
          </Typography>
        </Box>
        <Box className="py-8 flex flex-row">
          <Typography variant="body-1">
            Before creating your account, please read our&nbsp;
            <Link onClick={() => toggleModalIsOpen()}>
              End-User-License Agreement (EULA).
            </Link>
          </Typography>
        </Box>
        <Box className="pb-8">
          <CheckboxInput
            checked={acceptedDate !== ''}
            onClick={() => {
              setAcceptedDate(
                acceptedDate === '' ? new Date().toLocaleString() : ''
              )
            }}
            text="I've read and accept the End-User-License Agreement (EULA)."
          />
        </Box>
        {legalDisclaimers.error && (
          <Box className="pb-8">
            <MessageBanner
              variant={MessageBannerVariant.Error}
              icon={ErrorIcon}
              message={legalDisclaimers.error}
            />
          </Box>
        )}
        <Box className="pb-8">
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            onClick={async () => {
              await validateAndNavigateToNextSection()
            }}
          >
            <Typography variant="label-CTA">Next</Typography>
          </Button>
        </Box>
        <Typography variant="label-CTA" color={colorPrimitives.redGaming}>
          <ReactRouterLink to={'/'}>
            Already have an account? Sign In
          </ReactRouterLink>
        </Typography>
      </Box>
      <EULADialog
        showEULAUpdatedLabel={false}
        showCancelButton={true}
        isModalOpen={isModalOpen}
        onConfirm={() => toggleModalIsOpen()}
        onClose={() => {}}
      ></EULADialog>
    </>
  )
}
