import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Stack,
  Typography,
  Box,
  TableBody,
} from '@mui/material'
import { type DayName, daysOfWeek, getDayName } from 'utils/util'
import {
  type DateString,
  type Measures,
  type MeasureName,
  type WeeklySnapshotDatum,
  addMeasures,
  divideMeasures,
  buildMeasures,
  groupMeasuresByWeek,
} from 'utils/weeklySnapshotReportUtils'
import * as _ from 'lodash'
import { colorPrimitives } from 'components/Theme'

const emptyMeasures = {
  CashIn: 0,
  CreditsWagered: 0,
  CreditsWageredComp: 0,
  NTI: 0,
  LocationNTI: 0,
}

interface DateAndMeasures {
  date: DateString
  measures: Measures
}

type SevenDaysData = Record<DayName, DateAndMeasures>

const buildDataFromWeek = (dateMeasures: DateAndMeasures[]) => {
  return dateMeasures.reduce<Partial<SevenDaysData>>((m, dateAndMeasures) => {
    return {
      ...m,
      [getDayName(new Date(dateAndMeasures.date))]: dateAndMeasures,
    }
  }, {})
}

const isCompleteSevenDays = (
  data: Partial<SevenDaysData>
): data is SevenDaysData => {
  return daysOfWeek.every((dow) => !!data[dow])
}

interface SnapshotTableHeaderProps {
  title: string
  subtitle?: string
}

const SnapshotTableHeader = ({ title, subtitle }: SnapshotTableHeaderProps) => {
  return (
    <TableCell>
      <Stack alignItems="center" justifyContent="start">
        <Typography variant="body-1">{title}</Typography>
        {/* This &nbsp; is to coerce an extra line in order to match the height of our neighbors */}
        <Typography variant="body-3" color={colorPrimitives.black}>
          {subtitle}&nbsp;
        </Typography>
      </Stack>
    </TableCell>
  )
}

const formatDate = (dateString: string) => {
  // This might appear ham-fisted, but this seems to avoid an off-by-1 issue
  //  caused by date-fns() playing poorly with timezones
  const d = new Date(dateString)
  return `${1 + d.getUTCMonth()}/${d.getUTCDate()}/${d.getUTCFullYear()}`
}

const Money = ({ amount }: { amount: number }) => {
  const formattedAmount = Math.round(amount).toLocaleString()
  return (
    <Stack direction="row" justifyContent="center">
      <Typography variant="numbers">${formattedAmount}</Typography>
    </Stack>
  )
}

interface MeasureRowProps {
  measureName: MeasureName
  lastSevenDays: SevenDaysData
  latestDayName: DayName
  label: string
  historicalMeasureAverage: Measures
  showDate?: boolean
  showLastEightWeeks?: boolean
}

const MeasureRow = ({
  measureName,
  historicalMeasureAverage,
  lastSevenDays,
  latestDayName,
  label,
  showDate = false,
  showLastEightWeeks = true,
}: MeasureRowProps) => {
  const sevenDayTotal = daysOfWeek
    .map((dow) => lastSevenDays[dow].measures[measureName])
    .reduce((m, d) => m + d, 0)

  return (
    <TableRow>
      <TableCell align="center" className="border-r-2">
        {label}
      </TableCell>
      {daysOfWeek.map((dayName) => {
        const dayData = lastSevenDays[dayName]
        const highlightRow = latestDayName === dayName

        return (
          <TableCell
            className={`${highlightRow && 'bg-[#f7d971]'}`}
            key={`${measureName}-${dayName}`}
          >
            <Stack alignItems="center">
              <Money amount={dayData.measures[measureName]} />
              {showDate && (
                <Box className="pt-1">
                  <Typography variant="body-3" color={colorPrimitives.black}>
                    {formatDate(dayData.date)}
                  </Typography>
                </Box>
              )}
            </Stack>
          </TableCell>
        )
      })}
      <TableCell className="border-x-2">
        <Stack alignItems="center">
          <Money amount={sevenDayTotal} />
          {/* Another &nsbp; for sake of vertical alignment, this shouldn't exist... and yet, */}
          {showDate && (
            <Box className="pt-1">
              <Typography variant="body-3">&nbsp;</Typography>
            </Box>
          )}
        </Stack>
      </TableCell>
      <TableCell>
        {showLastEightWeeks && (
          <Money amount={historicalMeasureAverage[measureName]} />
        )}
      </TableCell>
    </TableRow>
  )
}

interface WeeklySnapshotTableProps {
  data: WeeklySnapshotDatum[]
}

export const WeeklySnapshotTable = ({ data }: WeeklySnapshotTableProps) => {
  const allMeasures = buildMeasures(data)
  const measuresByWeek = groupMeasuresByWeek(allMeasures)

  const weeklyMeasureTotals = measuresByWeek.map((weeklyData) => {
    return weeklyData.reduce<Measures>(
      (m, { measures }) => addMeasures(m, measures),
      emptyMeasures
    )
  })

  const historicalMeasureAverage = divideMeasures(
    weeklyMeasureTotals.reduce<Measures>(
      (m, measures) => addMeasures(m, measures),
      emptyMeasures
    ),
    weeklyMeasureTotals.length
  )

  const lastSevenDays = buildDataFromWeek(measuresByWeek[0])
  const previousWeekSevenDays = buildDataFromWeek(measuresByWeek[1])

  const earliestDay = _.min(Object.keys(allMeasures))
  const latestDay = _.max(Object.keys(allMeasures))

  if (
    !earliestDay ||
    !latestDay ||
    !isCompleteSevenDays(lastSevenDays) ||
    !isCompleteSevenDays(previousWeekSevenDays)
  ) {
    return <Typography variant="body-1"></Typography>
  }

  const latestDayName = getDayName(new Date(latestDay))

  return (
    <TableContainer component={Paper}>
      <Table aria-label="Weekly Snapshot Table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {daysOfWeek.map((dayName) => (
              <SnapshotTableHeader
                key={`weekly-snapshot-header-${dayName}`}
                title={dayName}
                subtitle={formatDate(lastSevenDays[dayName].date)}
              />
            ))}
            <SnapshotTableHeader title="7-Day Total" />
            <SnapshotTableHeader
              title="Last 8 Weeks Avg"
              subtitle={`Since ${formatDate(earliestDay)}`}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          <MeasureRow
            lastSevenDays={lastSevenDays}
            latestDayName={latestDayName}
            historicalMeasureAverage={historicalMeasureAverage}
            label="Cash In"
            measureName="CashIn"
          />

          <MeasureRow
            lastSevenDays={lastSevenDays}
            latestDayName={latestDayName}
            historicalMeasureAverage={historicalMeasureAverage}
            label="Credits Wagered"
            measureName="CreditsWagered"
          />

          <MeasureRow
            lastSevenDays={lastSevenDays}
            latestDayName={latestDayName}
            historicalMeasureAverage={historicalMeasureAverage}
            label="NTI (Total)"
            measureName="NTI"
          />

          <MeasureRow
            lastSevenDays={lastSevenDays}
            latestDayName={latestDayName}
            historicalMeasureAverage={historicalMeasureAverage}
            label="NTI (Location Share)"
            measureName="LocationNTI"
          />

          <MeasureRow
            lastSevenDays={previousWeekSevenDays}
            latestDayName={latestDayName}
            historicalMeasureAverage={historicalMeasureAverage}
            label="Credits Wagered (Prior Week)"
            measureName="CreditsWagered"
            showDate={true}
            showLastEightWeeks={false}
          />
        </TableBody>
      </Table>
    </TableContainer>
  )
}
