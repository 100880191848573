import { Stack, Typography } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { format } from 'date-fns'
import {
  CreditsWageredByMachineChart,
  type DataItem,
} from './CreditsWageredByMachineChart'
import { useLayout } from 'hooks/useLayout'
import { useGetCreditsWageredByMachine } from 'hooks/api/Reports/useGetCreditsWageredByMachine'
import { getReportStartAndEndDates } from 'utils/util'
import { ReportsHeader } from '../ReportsHeader'
import { useSetLe } from 'stores/useSetLe'

export const CreditsWageredByMachinePage = () => {
  const { passedLe } = useSetLe()
  const isMobile = useLayout()

  const { startDate, endDate } = getReportStartAndEndDates(8, 1)

  const reportQuery = useGetCreditsWageredByMachine({
    licensedEstablishmentId: Number(passedLe?.id),
    startDate,
    endDate,
  })

  const formattedStartDate = format(startDate, 'MM/dd/yyyy')
  const formattedEndDate = format(endDate, 'MM/dd/yyyy')

  return (
    <ReportsHeader currentTab={'3'}>
      <Typography variant="h4">
        {`Last 8 Weeks: ${formattedStartDate} - ${formattedEndDate}`}
      </Typography>
      <Stack spacing={4}>
        {reportQuery.isPending && reportQuery.isFetching && (
          <ActivityIndicator />
        )}
        {reportQuery.isError && <ErrorIndicator />}
        {reportQuery.data &&
          (reportQuery.data.metrics.length === 0 ? (
            <Typography variant="h4"></Typography>
          ) : (
            <CreditsWageredByMachineChart
              data={reportQuery.data.metrics.map(
                (item) =>
                  ({
                    amount: Number(item.amount),
                    name: String(item.name),
                    tagNumber: Number(item.tagNumber),
                    vgtNumber: String(item.vgtNumber),
                    percentage: Number(item.percentage),
                  }) satisfies DataItem
              )}
              mobile={isMobile.isMobile}
            />
          ))}
      </Stack>
    </ReportsHeader>
  )
}
