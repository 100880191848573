import { Stack, Typography } from '@mui/material'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { format } from 'date-fns'
import {
  CreditsWageredByHourChart,
  type DataItem,
} from './CreditsWageredByHourChart'
import { useGetCreditsWageredByHour } from 'hooks/api/Reports/useGetCreditsWageredByHour'
import { getReportStartAndEndDates } from 'utils/util'
import { ReportsHeader } from '../ReportsHeader'
import { useSetLe } from 'stores/useSetLe'

export const CreditsWageredByHourPage = () => {
  const { passedLe } = useSetLe()
  const { startDate, endDate } = getReportStartAndEndDates(8, 1)

  const reportQuery = useGetCreditsWageredByHour({
    licensedEstablishmentId: Number(passedLe?.id),
    startDate,
    endDate,
  })

  const formattedStartDate = format(startDate, 'MM/dd/yyyy')
  const formattedEndDate = format(endDate, 'MM/dd/yyyy')

  return (
    <ReportsHeader currentTab={'2'}>
      <Typography variant="h4">
        {`Last 8 Weeks: ${formattedStartDate} - ${formattedEndDate}`}
      </Typography>
      <Stack spacing={4}>
        {reportQuery.isPending && reportQuery.isFetching && (
          <ActivityIndicator />
        )}
        {reportQuery.isError && <ErrorIndicator />}
        {reportQuery.data &&
          (reportQuery.data.metrics.length === 0 ? (
            <Typography variant="h4"></Typography>
          ) : (
            <CreditsWageredByHourChart
              data={reportQuery.data.metrics.map(
                (item) =>
                  ({
                    hour: Number(item.hour),
                    amount: Number(item.amount),
                    percentage: Number(item.percentage),
                  }) satisfies DataItem
              )}
            />
          ))}
      </Stack>
    </ReportsHeader>
  )
}
