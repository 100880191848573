import {
  Box,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { colorPrimitives } from './Theme'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export const DateRangeSelectHeader = ({
  label,
  numDays,
  setNumDays,
  setStartDate,
  dateOffset,
  children,
}: {
  label?: string
  setNumDays: (days: string) => void
  setStartDate: (startDate: Date) => void
  dateOffset?: (date: number) => Date
  numDays: string
  children: React.ReactNode
}) => {
  const handleChange = (e: SelectChangeEvent) => {
    const days = -Number(e.target.value)
    setNumDays(e.target.value)
    if (dateOffset) {
      setStartDate(dateOffset(days))
    }
  }

  return (
    <Stack spacing={'24px'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: { xs: 'start', sm: 'space-between' },
          alignItems: { xs: 'start', sm: 'center' },
          rowGap: '8px',
        }}
      >
        <Box>
          <Typography variant="h3">{label}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            'svg path': {
              fill: colorPrimitives.redGaming,
            },
          }}
        >
          <Select
            value={numDays}
            onChange={handleChange}
            displayEmpty
            sx={{
              padding: '0px 0px 0px 0px !important',
              boxShadow: 'none',
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
              },
              '.Mui-focused': { border: 'none !important' },
              color: colorPrimitives.redGaming,
            }}
            IconComponent={KeyboardArrowDownIcon}
          >
            <MenuItem value={7}>Last 7 Days</MenuItem>
            <MenuItem value={30}>Last 30 Days</MenuItem>
            <MenuItem value={90}>Last 3 Months</MenuItem>
            <MenuItem value={365}>Year to Date</MenuItem>
          </Select>
        </Box>
      </Box>

      {children}
    </Stack>
  )
}
